<style scoped>
.livelist {
  width: 90%;
  margin: 0 auto 10px;
  padding: 10px 20px;
  background: white;
  position: relative;
  border-radius: 3px;
  box-shadow: 0 1px 6px rgb(222 137 115 / 12%);
}
.livelist .liveTitle {
  font-size: 16px;
  font-weight: bold;
  color: rgba(51, 51, 51, 1);
  text-align: left;
  z-index: 999;
  margin-top: 18px;
}
.livelist .bookmark {
  position: absolute;
  left: 0;
  top: 0;
  height: 32px;
  width: 4px;
  border-radius: 3px;
}
.livelist .live-status {
  background: rgb(255, 193, 74);
  padding: 0 20px;
  height: 30px;
  line-height: 30px;
  font-size: 13px;
  color: white;
  /* border-top-left-radius: 3px; */
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  /* border-bottom-left-radius: 3px; */
  letter-spacing: 2px;
  margin-bottom: 10px;
}
.livelist .live-status-grey {
  background: rgb(219, 219, 219);
}
.livelist .live-status-loading {
  background: rgb(156, 199, 5);
}

.livelist .live-status-end {
  background: rgb(112 170 230);
}
.live-status-left {
  width: 0;
  height: 0;
  border-top: 30px solid rgb(255, 193, 74);
  border-left: 18px solid transparent;
}
.live-status-left-loading {
  border-top: 30px solid rgb(156, 199, 5);
}
.live-status-left-grey {
  border-top: 30px solid rgb(219, 219, 219);
}
.live-status-left-end {
  border-top: 30px solid rgb(112 170 230);
}

.liveTime {
  text-align: left;
  font-size: 14px;
  color: rgb(233, 83, 109);
  margin: 4px 0;
}
.line {
  width: 100%;
  margin: 20px auto;
  height: 1px;
  background: rgb(204 204 204 / 23%);
}
.avatar {
  width: 24px;
  height: 24px;
  margin-top: -3px;
  border-radius: 50%;
}
</style>
<template>
  <div style="">
    <div class="livelist" v-for="(item, index) in liveList" :key="index">
      <div style="display: flex" @click="toDetail(item.liveStatus, item)">
        <div class="bookmark" :style="{ background: item.bookmarkColor }"></div>
        <div class="liveTitle">
          {{ item.title }}
        </div>
      </div>
      <div class="liveTime" @click="toDetail(item.liveStatus, item)">
        <span v-if="item.time">
          <span v-if="item.time != '全天播放'">{{ item.time }} </span>
          <span v-else style="color: #808695">{{ item.time }} </span>
        </span>
        <span v-else style="color: #ccc">
          暂无详细时间
        </span>
      </div>

      <div class="line"></div>

      <div
        v-on:click="toEdit(item)"
        style="display: flex; justify-content: space-between"
      >
        <div style="width: 60px; text-align: left">主持人:</div>
        <div style="flex: 2; display: flex; justify-content: flex-end">
          <div
            v-if="
              !item.authorList ||
                item.authorList.length == 0 ||
                !item.authorList[0].author
            "
          >
            无
          </div>
          <div v-else>
            <div v-for="(avatarItem, index) in item.authorList" :key="index">
              <div
                style="display: flex; justify-content: flex-end; margin-right: 8px"
              >
                <img class="avatar" :src="avatarItem.avatar" alt="" />
                <div style="margin-left: 8px">
                  {{ avatarItem.author }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        @click="toDetail(item.liveStatus, item)"
        style="display: flex; position: absolute; right: 0; top: 0"
      >
        <!-- 1 尚未开始 2 进行中 3 暂无状态 4 已结束 -->
        <div
          v-if="item.liveStatus == '1'"
          class="live-status-left live-status-left-loading"
        ></div>
        <div v-if="item.liveStatus == '2'" class="live-status-left"></div>
        <div
          v-if="item.liveStatus == '3'"
          class="live-status-left live-status-left-grey"
        ></div>
        <div
          v-if="item.liveStatus == '4'"
          class="live-status-left live-status-left-end"
        ></div>

        <div
          class="live-status live-status-loading"
          v-if="item.liveStatus == '1'"
        >
          尚未开始
        </div>
        <div v-if="item.liveStatus == '2'" class="live-status">进行中</div>
        <div class="live-status live-status-grey" v-if="item.liveStatus == '3'">
          暂无状态
        </div>
        <div class="live-status live-status-end" v-if="item.liveStatus == '4'">
          已结束
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable  */

export default {
  name: "rnContentlist",
  data() {
    return {};
  },

  props: {
    // 内容数据
    liveList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  components: {},
  methods: {
    toEdit(data) {
      console.log("data----", data);
      this.$emit("toEdit", { data });
    },
    toDetail(status, data) {
      if (status == "2" || status == "1" || status == "4") {
        console.log("status, ", status);
        this.$router.push({
          path: "/liveDetail",
          query: {
            title: data.title,
            time: data.time,
            _cid: data._cid,
            liveUrl: data.liveUrl,
            liveType: data.liveType,
          },
        });
      }
    },
    formatTen(num) {
      return num > 9 ? num + "" : "0" + num;
    },
    formatDate(updated) {
      const date = new Date(updated);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hour = date.getHours();
      const minute = date.getMinutes();

      const second = date.getSeconds();
      return (
        this.formatTen(month) +
        "月" +
        this.formatTen(day) +
        "日 " +
        this.formatTen(hour) +
        ":" +
        this.formatTen(minute) +
        ":" +
        this.formatTen(second)
      );
    },
    formatTime(date, startTime, endTime) {},
  },
  mounted() {},
  created() {},
};
</script>
