<style scoped>
.main-containers {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: rgba(248 249 255);
}
.Livetop {
  overflow: hidden;
  width: 100%;
  height: 120px;
  background: rgba(212, 36, 34, 1);
  border-bottom-right-radius: 200px 30px;
  border-bottom-left-radius: 200px 30px;
}

.liveSearch {
  background: rgba(218, 79, 78, 1);
  width: 90%;
  border-radius: 4px;
  margin: 0 auto;
  padding: 8px 20px;
  color: rgba(243, 183, 180, 1);
  display: flex;
  margin: 10px auto 10px;
  text-align: left;
}

.iconColor {
  color: rgba(243, 183, 180, 1);
}
input.liveSearchInput::-webkit-input-placeholder {
  color: rgba(243, 183, 180, 1);
}
.liveSearchInput {
  background: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  color: rgba(243, 183, 180, 1);
}
.myLiveList svg {
  fill: #f1c9c6;
}
</style>

<template>
  <div class="main-containers">
    <div class="main" style="width: 100vw; height: 100vh">
      <div class="Livetop">
        <div
          style="
            width: 90%;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 2px;
          "
        >
          <div class="liveSearch">
            <!-- <div class="liveSearch" @click="searchFun"> -->
            <!-- <div style="flex: 1">请输入直播信息...</div> -->
            <input
              v-model="searchlive"
              class="liveSearchInput"
              style="flex: 1"
              type="text"
              placeholder="请输入直播信息..."
            />
            <div style="width: 30px; text-align: right">
              <i
                @click="getLiveList"
                class="fa fa-search"
                aria-hidden="true"
              ></i>
            </div>
          </div>
          <div
            class="myLiveList"
            style="display: flex"
            @click="$router.push({ path: '/myLiveList' })"
          >
            <svg
              t="1701932195962"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="11262"
              width="36"
              height="36"
            >
              <path
                d="M900.388571 273.92a128.365714 128.365714 0 0 0-89.6-36.571429h-148.114285l49.005714-84.114285a36.571429 36.571429 0 0 0-10.971429-44.982857 31.085714 31.085714 0 0 0-24.868571-2.56 34.011429 34.011429 0 0 0-19.748571 15.725714L588.8 236.617143h-142.628571l-67.291429-116.297143a32.914286 32.914286 0 0 0-44.617143-13.165714 34.377143 34.377143 0 0 0-10.605714 45.348571l48.64 84.114286h-146.285714a126.902857 126.902857 0 0 0-128.365715 126.902857v429.348571a126.902857 126.902857 0 0 0 126.902857 126.902858h585.142858a127.268571 127.268571 0 0 0 126.902857-126.902858V363.52a128 128 0 0 0-36.205715-89.6z m-221.622857 340.114286l-237.714285 138.605714a20.845714 20.845714 0 0 1-31.085715-17.92v-277.211429a20.845714 20.845714 0 0 1 31.085715-17.92l237.714285 138.605715a20.845714 20.845714 0 0 1 0 36.571428z"
                p-id="11263"
              ></path>
            </svg>
          </div>
        </div>
      </div>
      <div
        style="
          margin: -60px auto 40px;
          width: 100vw;
          height: 90vh;
          overflow-y: scroll;
        "
      >
        <live-list-cmp-old
          :liveList="liveListDay"
          @toEdit="toEdit_old"
        ></live-list-cmp-old>
        <live-list-cmp :liveList="liveList" @toEdit="toEdit"></live-list-cmp>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable  */
import liveListCmpOld from "../../widgets/rnContentList.vue";
import liveListCmp from "../../widgets/rnContentListToo.vue";
import { wxConPost } from "../../api/httpApi";
import wxLoginApi from "../../api/wxLoginApi";
import moment from "moment";

import _ from "lodash";

const debug = require("debug")("@main");

export default {
  name: "LiveList",
  data() {
    return {
      liveList: [],
      liveListDay: [],
      recordedList: [],
      timeOutTimer: null,
      userid: "",
      searchlive: "",
    };
  },
  computed: {},
  methods: {
    searchFun() {
      this.$router.push({
        path: "/search",
      });
    },
    getLocalStorage(key1, exp) {
      const data = localStorage.getItem(key1);
      //转为对象
      const dataObj = data ? JSON.parse(data) : {};
      if (new Date().getTime() - dataObj.time > exp) {
        return false;
      } else {
        if (dataObj.data) {
          const dataObjDatatoJson = JSON.parse(dataObj.data);
          return dataObjDatatoJson;
        } else {
          return false;
        }
      }
    },
    async getUserData() {
      // 判断是否有取过数据
      const userData = this.getLocalStorage("liveUserData", this.exp);
      if (userData && !_.isEmpty(userData)) {
        // 通知数据获取成功
        return userData;
      }
      try {
        if (_.isEmpty(userData) || _.isUndefined(userData)) {
          const result = await wxConPost(
            "/services/gttt-user-mgt/wx/getUserData",
            {}
          );
          _d("---------------------getUserData", result);
          // 数据写入store中
          this.setLocalStorage("liveUserData", JSON.stringify(result));
        }
        // 通知数据获取成功
        return result;
      } catch (e) {
        return {};
        _d("xxxxxxxxxxx获取用户其他数据Err", e);
      }
    },
    geyRgb() {
      //rgb颜色随机
      const r = Math.floor(Math.random() * 256);
      const g = Math.floor(Math.random() * 256);
      const b = Math.floor(Math.random() * 256);
      const rgb = "(" + r + "," + g + "," + b + ")";
      return rgb;
    },
    async getLiveList() {
      try {
        const liveList = this.$store.state.columnnew;
        if (!liveList || liveList.length == 0) {
          const ret = await wxConPost(
            "/services/gttt-live-mgt/deptLive/getLiveList",
            { live: this.searchlive }
          );
          debug("this.liveList----", ret);
          if (ret.ret && ret.ret.length > 0) {
            for (const iterator of ret.ret) {
              const data = iterator;
              let time = `${moment(iterator.startTime).format(
                "YYYY-MM-DD HH:mm"
              )} ${moment(iterator.endTime).format("YYYY-MM-DD HH:mm")}`;

              Object.assign(data, {
                time: time,
                liveStatus: 2,
                authorList: [
                  { author: iterator.author, avatar: "/img/logo.jpeg" },
                ],
                bookmarkColor: `rgb${this.geyRgb()}`,
              });
              this.liveList.push(data);
            }
            this.$store.state.columnnew = this.liveList;
            debug("222----", this.$store.state.columnnew);
          }
        } else {
          this.liveList = this.$store.state.columnnew;
        }
      } catch (error) {
        debug("----", error);
      }
    },

    async getLiveList_old() {
      try {
        const liveListDay = this.$store.state.column;

        if (!liveListDay || liveListDay.length == 0) {
          const ret = await wxConPost(
            "/services/gttt-live-mgt/wxLive/getWxLiveList"
          );
          debug("this.liveList----", ret);
          if (ret.ret && ret.ret.length > 0) {
            for (const iterator of ret.ret) {
              const data = iterator;
              let time = iterator.date
                ? `${moment(iterator.date).format("YYYY.MM.DD")} ${
                    iterator.startTime
                  }-${iterator.endTime}`
                : "";

              if (iterator.liveFullDay) {
                time = "全天播放";
              }
              Object.assign(data, {
                time: time,
                authorList: [
                  { author: iterator.author, avatar: "/img/logo.jpeg" },
                ],

                bookmarkColor: `rgb${this.geyRgb()}`,
              });
              this.liveListDay.push(data);
            }
            this.$store.state.column = this.liveListDay;
            debug("222----", this.$store.state.column);
          }

          debug("this.userid----", this.userid);
        } else {
          let curList = [];

          for (const iterator of liveListDay) {
            let data = iterator;
            if (!data.time || !data.authorList || !data.bookmarkColor) {
              let time = iterator.date
                ? `${moment(iterator.date).format("YYYY.MM.DD")} ${
                    iterator.startTime
                  }-${iterator.endTime}`
                : "";

              if (iterator.liveFullDay) {
                time = "全天播放";
              }
              Object.assign(data, {
                time: time,
                authorList: [
                  { author: iterator.author, avatar: "/img/logo.jpeg" },
                ],

                bookmarkColor: `rgb${this.geyRgb()}`,
              });
            }
            curList.push(data);
          }
          this.liveListDay = curList;
        }
      } catch (error) {
        debug("----", error);
      }
    },
    toEdit(detail) {
      if (detail.isMobileRtcLive) {
        this.$router.push({
          path: "/playRtc",
          query: {
            roomId: detail.liveId,
          },
        });
      } else {
        this.$router.push({
          path: "/liveLineDetail",
          query: detail,
        });
      }
    },

    toEdit_old(detail) {
      debug("toEdit", detail, this.userid);
      const data = detail.data;

      if (
        this.userid == "13919267170"
        // ||
        // this.userid == "zhengdongyang" ||
        // this.userid == "1548992604063aa4bedfbfd16007bff65161d55654c63b6d4053ea5"
      ) {
        _.unset(data, "_id");
        _.unset(data, "authorList");

        _.unset(data, "bookmarkColor");
        _.unset(data, "time");
        _.unset(data, "updated");

        this.$router.push({
          path: "/editLive",
          query: data,
        });
      }
    },
  },
  components: { liveListCmp, liveListCmpOld },
  async created() {
    wxLoginApi.onReady(async () => {
      await this.getLiveList_old();
      await this.getLiveList();
      const userInfo = await this.getUserData();
      this.userid = userInfo.userid;
      // if (this.userid == "13919267170" || this.userid == "zhengdongyang") {
      //   if (this.liveListDay[this.liveList.length - 1].title != "空闲") {
      //     this.liveListDay.push({
      //       title: "空闲",
      //       time: "",
      //       liveStatus: "3",
      //       authorList: [],
      //       bookmarkColor: "rgb(255,196,114)",
      //     });
      //   }
      // }
    });
  },
  beforeCreate() {
    document.title = "直播列表";
  },
  destroyed() {
    debug("destroyed");
  },
};
</script>
